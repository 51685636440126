<template>
  <div>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"
                style="padding-top:0px;background: none;border: none;"/>
    <div class="card p-d-flex p-justify-center ">
      <div class="p-fluid">
        <strong style="margin-bottom: 2rem;font-size: 16px;display: block;text-align: center">分配专家</strong>
        <div class="p-field p-grid">
          <label class="p-col-fixed">项目名称：</label>
          <div class="p-col" style="color: #19a423;font-weight: 600">{{ name }}</div>
        </div>
        <div class="p-field p-grid">
          <label class="p-col-fixed">选择专家：</label>
          <div class="p-col">
            <MultiSelect v-model="from.userIdList" @change="show"
                         :filter="true"
                         :class="{'p-invalid':( from.userIdList.length===0 && submitted)}"
                         :options="allExportList" optionLabel="name" option-value="id" placeholder="请选择专家"
                         display="chip"/>
            <span v-if="from.userIdList.length===0 && submitted" style="color: red">请选择专家</span>
          </div>
        </div>
        <div class="p-field p-grid">
          <div class="p-col">
            <Button @click="submit()">分配</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "distribuirExport",

  data() {
    return {
      breadcrumb: {
        home: {label: '种植规划', to: '/plan'},
        items: [
          {label: '列表', to: '#'},
        ],
      },
      from: {
        planId: null,
        userIdList: []
      },
      submitted: false,
      allExportList: [],
      name: null
    }
  },
  mounted() {
    this.from.planId = this.$route.query.id
    this.name = this.$route.query.name
    this.getExperts()
    this.getExportList()
  },

  methods: {
    getExperts() {
      this.$http('/plan/getExperts', 'get', {id: this.from.planId}, (res) => {
        console.log(res)
        res.data.forEach(item=>{
          this.from.userIdList.push(item.userId)
        })

      })
    },
    getExportList() {
      this.$http('user/getUserList', 'get', {
        type: 2
      }, (res) => {
        this.allExportList = res.data
      })
    },
    submit(isInvalid) {
      this.submitted = true
      if (this.from.userIdList.length === 0) {
        return
      }
      this.$http('plan/distributionExpert', 'post', this.from, () => {
        // this.$router.push('/plan');
        this.$toast.add({severity: 'success', summary: '分配成功', life: 3000})
        this.$router.push('/plan')
      })
    }

  }
}
</script>

<style scoped>
label {
  width: 100px;
  text-align: right;
  justify-content: flex-end;
}

.p-fluid .p-button {
  width: auto;
  margin-left: 100px;
}
</style>
